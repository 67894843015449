import React, {
  forwardRef,
  HTMLAttributes,
  HTMLProps,
  useContext,
} from "react";
import { GatsbyLinkProps, Link } from "gatsby";
import cx from "classnames";
import { motion } from "framer-motion";

import { DEFAULT_TRANSITION } from "utils/constants";
import { THEME, ThemeContext } from "components/common/ThemeProvider";

import * as s from "./Button.module.sass";

const themeClasses = {
  primary: s.primary,
  secondary: s.secondary,
};

type ButtonProps = (
  | HTMLProps<HTMLButtonElement>
  | HTMLProps<HTMLAnchorElement>
  | GatsbyLinkProps<any>
) & {
  theme?: keyof typeof themeClasses;
  stableDarkColor?: boolean;
  className?: string;
  children: any;
};

export const Button = forwardRef<any, ButtonProps>(
  (
    {
      theme = "primary",
      stableDarkColor = false,
      className,
      children,
      ...rest
    },
    ref
  ) => {
    const { theme: globalTheme } = useContext(ThemeContext);
    const classNameList = cx(
      s.root,
      { [s.stableDarkColor]: stableDarkColor },
      { [s.dark]: globalTheme === THEME.dark },
      themeClasses[theme],
      className
    );

    if ("to" in rest) {
      return (
        <motion.span
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.98 }}
          transition={{ ...DEFAULT_TRANSITION, duration: 0.3, delay: 0 }}
          ref={ref}
          className={cx(classNameList, s.linkWrapper)}
        >
          {/*@ts-ignore*/}
          <Link {...(rest as GatsbyLinkProps<any>)} className={s.link}>
            {children}
          </Link>
        </motion.span>
      );
    }

    if ("href" in rest) {
      return (
        <motion.a
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.98 }}
          transition={{ ...DEFAULT_TRANSITION, duration: 0.3, delay: 0 }}
          // @ts-ignore
          ref={ref}
          target="_blank"
          rel="noopener nofollow"
          className={classNameList}
          {...(rest as HTMLProps<HTMLAnchorElement>)}
        >
          {children}
        </motion.a>
      );
    }

    return (
      // @ts-ignore
      <motion.button
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 0.98 }}
        transition={{ ...DEFAULT_TRANSITION, duration: 0.3, delay: 0 }}
        ref={ref}
        // @ts-ignore
        type={rest.type ?? "button"}
        className={classNameList}
        {...(rest as HTMLAttributes<HTMLButtonElement>)}
      >
        {children}
      </motion.button>
    );
  }
);
