import { graphql, useStaticQuery } from "gatsby";

import { IAdvantage } from "types";

export const useHomeAdvantages = () => {
  const data = useStaticQuery(graphql`
    query Advantages {
      prismicHomePage {
        data {
          advantages {
            advantages_image {
              url
            }
            advantages_title {
              richText
            }
            advantages_description
          }
        }
      }
    }
  `);

  return data.prismicHomePage.data.advantages
    ?.map((el: any) =>
      el && el.advantages_title.richText
        ? {
            imageUrl: el.advantages_image.url,
            title: el.advantages_title.richText,
            description: el.advantages_description,
          }
        : null
    )
    .filter((el: IAdvantage | null) => el) as IAdvantage[] | undefined;
};
