import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";

import {
  DEFAULT_ACCENT_COLOR,
  DEFAULT_COLOR_VARIABLES,
  DEFAULT_COLOR_VARIABLES_DARK,
  DEFAULT_COLOR_VARIABLES_DARK_SHARED,
  DEFAULT_COLOR_VARIABLES_SHARED,
} from "utils/constants";
import { isColorRed } from "utils/helpers";
import { THEME, ThemeContext } from "components/common/ThemeProvider";

type ColorContextType = {
  setColor: (value: string) => void;
};

const initialContextValue = {
  setColor: () => null,
};

export const ColorContext =
  createContext<ColorContextType>(initialContextValue);

type ColorProviderProps = {
  pathname: string;
};

export const ColorProvider: FC<ColorProviderProps> = ({
  pathname,
  children,
}) => {
  const { theme } = useContext(ThemeContext);

  const handleSetColor = useCallback(
    (newColor: string) => {
      if (!theme) return;

      const newColorsObject =
        newColor === DEFAULT_ACCENT_COLOR
          ? theme === THEME.dark
            ? DEFAULT_COLOR_VARIABLES_DARK
            : DEFAULT_COLOR_VARIABLES
          : {
              ...(theme === THEME.dark
                ? DEFAULT_COLOR_VARIABLES_DARK_SHARED
                : DEFAULT_COLOR_VARIABLES_SHARED),
              "--labels-color": newColor,
              "--labels-color-50": `${newColor}80`,
              "--accent-color": newColor,
              "--accent-light-text-color": newColor
                .split("")
                .map((el, index) => (index % 2 === 1 ? "F" : el))
                .join(""),
              "--accent-color-or-dark": isColorRed(newColor)
                ? theme === THEME.dark
                  ? "#F1F3F8"
                  : "#28282D"
                : newColor,
              "--accent-color-5": `${newColor}0d`,
              "--accent-color-10": `${newColor}1a`,
              "--accent-color-15": `${newColor}33`,
              "--accent-color-70": `${newColor}b3`,
            };
      for (const [key, value] of Object.entries(newColorsObject)) {
        document.documentElement.style.setProperty(key, value);
      }
    },
    [theme]
  );

  useEffect(() => {
    if (pathname === "/" || pathname === "/blog/") {
      handleSetColor(DEFAULT_ACCENT_COLOR);
    }
  }, [handleSetColor, pathname]);

  return (
    <ColorContext.Provider value={{ setColor: handleSetColor }}>
      {children}
    </ColorContext.Provider>
  );
};
