export const COMPANY_NAME = "StakeMe";

export const DEFAULT_ACCENT_COLOR = "#3535DB";
export const DEFAULT_ACCENT_COLOR_DARK = "#6464E9";
export const DEFAULT_COLOR_VARIABLES_SHARED = {
  "--color-lines-primary": "#898D9C",
  "--color-main-solid": "#ffffff",
  "--color-dark-main": "#28282D",
  "--color-main": "#F1F3F8",
  "--color-text-gray": "#A2A2AE",
  "--color-not-found-white": "#ffffff",
  "--color-not-found-border": "#28282D",

  "--color-twitter": "#1DA1F2",
  "--color-discord": "#5769EA",
  "--color-telegram": "#52A8E6",
  "--color-youtube": "#EB3223",
  "--color-tiktok": "#000000",
  "--color-teletype": "#2D7BF6",
  "--color-github": "#171B21",
};
export const DEFAULT_COLOR_VARIABLES = {
  ...DEFAULT_COLOR_VARIABLES_SHARED,
  "--labels-color": "#A2A2AE",
  "--labels-color-50": "#A2A2AE",
  "--accent-color": "#3535DB",
  "--accent-color-or-dark": "#3535DB",
  "--accent-color-5": "#F1F3F8",
  "--accent-color-10": "transparent",
  "--accent-color-15": "#3535DB33",
  "--accent-color-70": "#3535DBb3",
};
export const DEFAULT_COLOR_VARIABLES_DARK_SHARED = {
  "--color-lines-primary": "#37383F",
  "--color-main-solid": "#1c1c1c",
  "--color-dark-main": "#F1F3F8",
  "--color-main": "#1c1c1c",
  "--color-text-gray": "#63636A",
  "--color-not-found-white": "#272729",
  "--color-not-found-border": "#000000",

  "--color-twitter": "#157DD0",
  "--color-discord": "#808FF2",
  "--color-telegram": "#66BAEA",
  "--color-youtube": "#F37057",
  "--color-tiktok": "#2C2C2E",
  "--color-teletype": "#60A2F9",
  "--color-github": "#292E36",
};
export const DEFAULT_COLOR_VARIABLES_DARK = {
  ...DEFAULT_COLOR_VARIABLES_DARK_SHARED,
  "--labels-color": "#63636A",
  "--labels-color-50": "#63636A",
  "--accent-color": "#6464E9",
  "--accent-color-or-dark": "#6464E9",
  "--accent-color-5": "#1C1C1E",
  "--accent-color-10": "transparent",
  "--accent-color-15": "#6464E933",
  "--accent-color-70": "#6464E9b3",
};
export const SOCIAL_COLORS = {
  twitter: "#1DA1F2",
  discord: "#5769EA",
  telegram: "#52A8E6",
  youtube: "#EB3223",
  tiktok: "#000000",
  teletype: "#2D7BF6",
  github: "#171B21",
};

export const MULTI_COLORS = ["#3535DB", "#082FFB", "#019E74", "#FF005C"];
export const MULTI_COLORS_DARK = ["#8585F4", "#6A86FD", "#5BE1A3", "#FF667E"];

export const COINGECKO_API = {
  URL:
    process.env.GATSBY_COINGECKO_API ??
    "https://api.coingecko.com/api/v3/simple/price",
  IDS_KEY: "ids",
  CURRENCY_KEY: "vs_currencies",
  DEFAULT_CURRENCY: "usd",
};

export const DEFAULT_SEO = {
  URL: "https://stakeme.io/",
  SITE_NAME: "stakeme.io",
  TWITTER: "@ProNodes_val",
};

export const OG_IMAGES_API =
  process.env.GATSBY_OG_IMAGES_API ?? "https://og-images.stakeme.io/api/";

export const DEFAULT_TRANSITION = {
  ease: [0.6, 0.01, 0, 0.95],
  duration: 1,
  delay: 0.3,
};

export const DEFAULT_TRANSITION_DELAY_STAGGER = 0.15;

export const DEVICE_WIDTH = {
  S_PHONE: 375,
  PHONE: 425,
  L_PHONE: 620,
  TABLET: 768,
  L_TABLET: 1024,
  DESKTOP: 1366,
  L_DESKTOP: 1440,
  LG_DESKTOP: 1920,
};
