import React, { createContext } from "react";
import { WindowLocation } from "@reach/router";

type LocationContextType = {
  location: WindowLocation<WindowLocation["state"]>;
};

export const LocationContext = createContext<LocationContextType>({
  // @ts-ignore
  location: undefined,
});

type LocationProviderType = {
  location: WindowLocation<WindowLocation["state"]>;
};

export const LocationProvider: FC<LocationProviderType> = ({
  location,
  children,
}) => (
  <LocationContext.Provider value={{ location }}>
    {children}
  </LocationContext.Provider>
);
