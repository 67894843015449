import { getImage } from "gatsby-plugin-image";

import { IBlogArticle, IBlogCategory, IBlogCategoryFull } from "types";

export const prepareBlogCategoryData = (data: any) => {
  const category = data.prismicBlogCategory.data;
  const articles = data.allPrismicBlogArticle.nodes;

  return {
    category: {
      name: category.name,
      description: category.description,
      image: getImage(category.image.localFile),
      imageUrl: category.image.url,
    },
    articles: articles
      ?.map((article: any) =>
        article && article.uid && article.data.title
          ? {
              uid: article.uid,
              date: article.last_publication_date,
              image: getImage(article.data.image.localFile),
              title: article.data.title,
              color:
                article.data.categories?.[0]?.category.document?.data?.color,
              darkColor:
                article.data.categories?.[0]?.category.document?.data
                  ?.dark_color,
              categories: article.data.categories
                ?.map((cat: any) =>
                  cat &&
                  cat.category &&
                  cat.category.document &&
                  cat.category.document.uid &&
                  cat.category.document.data.name
                    ? {
                        uid: cat.category.document.uid,
                        name: cat.category.document.data.name,
                        color: cat.category.document.data.color,
                        darkColor: cat.category.document.data.dark_color,
                      }
                    : null
                )
                .filter((el: IBlogCategory | null) => el) as
                | IBlogCategory[]
                | undefined,
            }
          : null
      )
      .filter((el: IBlogArticle | null) => el) as IBlogArticle[] | undefined,
  } as IBlogCategoryFull;
};
