import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { IProject } from "types";

export const useHomeMainnetProjects = () => {
  const data = useStaticQuery(graphql`
    query MainnetProjects {
      prismicHomePage {
        data {
          portfolio {
            project {
              document {
                ... on PrismicProject {
                  uid
                  data {
                    icon {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            width: 32
                            placeholder: BLURRED
                            height: 32
                          )
                        }
                      }
                    }
                    name
                    apr
                    comission
                    primary_color
                    dark_color
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.prismicHomePage.data.portfolio
    ?.map((el: any) =>
      el &&
      el.project &&
      el.project.document &&
      el.project.document.uid &&
      el.project.document.data &&
      el.project.document.data.name
        ? {
            uid: el.project.document.uid,
            name: el.project.document.data.name,
            icon: getImage(el.project.document.data.icon.localFile),
            apr: el.project.document.data.apr,
            commission: el.project.document.data.comission,
            color: el.project.document.data.primary_color,
            darkColor: el.project.document.data.dark_color,
          }
        : null
    )
    .filter((el: IProject | null) => el) as IProject[] | undefined;
};
