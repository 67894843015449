import React from "react";
import cx from "classnames";

import { useTelegramBotLink } from "hooks/api";
import Icon from "svg/telegram-bot.svg";

import * as s from "./TelegramBotButton.module.sass";

type TelegramBotButtonProps = {
  mobileDark?: boolean;
};

export const TelegramBotButton: FC<TelegramBotButtonProps> = ({
  mobileDark,
  className,
}) => {
  const link = useTelegramBotLink();

  if (!link) return null;

  return (
    <a
      href={link}
      target="_blank"
      rel="noopener nofollow noreferrer"
      className={cx(s.root, { [s.mobileDark]: mobileDark }, className)}
    >
      Monitoring
      <Icon className={s.icon} />
    </a>
  );
};
