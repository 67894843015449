import React from "react";

import { AnimatedLine } from "components/common/AnimatedLine";

import { Creator } from "./Creator";
import * as s from "./Footer.module.sass";

export const Footer: FC = () => (
  <footer className={s.root}>
    <AnimatedLine direction="horizontal" />
    <div className={s.copy}>
      &copy; {new Date().getFullYear()} Stakeme.io. All rights reserved
    </div>
    <Creator />
  </footer>
);
