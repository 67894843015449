import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { IBlogArticle, IBlogCategory } from "types";

export const useHomeBlog = () => {
  const data = useStaticQuery(graphql`
    query HomeBlog {
      allPrismicBlogArticle(sort: { last_publication_date: DESC }, limit: 3) {
        nodes {
          last_publication_date
          uid
          data {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 224, placeholder: BLURRED, height: 140)
                }
              }
            }
            title
            categories {
              category {
                document {
                  ... on PrismicBlogCategory {
                    uid
                    data {
                      name
                      color
                      dark_color
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.allPrismicBlogArticle.nodes
    ?.map((el: any) =>
      el && el.data.title
        ? {
            date: el.last_publication_date,
            uid: el.uid,
            title: el.data.title,
            image: getImage(el.data.image.localFile),
            color: el.data.categories?.[0]?.category.document?.data?.color,
            darkColor:
              el.data.categories?.[0]?.category.document?.data?.dark_color,
            categories: el.data.categories
              ?.map((cat: any) =>
                cat &&
                cat.category &&
                cat.category.document &&
                cat.category.document.uid &&
                cat.category.document.data.name
                  ? {
                      uid: cat.category.document.uid,
                      name: cat.category.document.data.name,
                      color: cat.category.document.data.color,
                      darkColor: cat.category.document.data.dark_color,
                    }
                  : null
              )
              .filter((el: IBlogCategory | null) => el) as
              | IBlogCategory[]
              | undefined,
          }
        : null
    )
    .filter((el: IBlogArticle | null) => el) as IBlogArticle[] | undefined;
};
