import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import cx from "classnames";
import { motion, useAnimation } from "framer-motion";
import { useDebouncedCallback, useThrottledCallback } from "use-debounce";

import { DEFAULT_SEO, DEFAULT_TRANSITION } from "utils/constants";
import { LocationContext } from "components/common/LocationProvider";
import { ProductInfoContext } from "components/common/ProductInfoProvider";
import { RefsContext } from "components/common/RefsProvider";
import { THEME, ThemeContext } from "components/common/ThemeProvider";
import { Logo } from "components/common/Logo";
import { Button } from "components/ui/Button";
import { TelegramBotButton } from "components/common/TelegramBotButton";
import { ModeToggle } from "components/common/ModeToggle";
import ArrowLeft from "svg/arrow-left.svg";
import LogoWhite from "svg/logo-white.svg";
import TwitterIcon from "svg/twitter-small.svg";

import { NAVIGATION } from "./Header.content";

import * as s from "./Header.module.sass";

const nameVariants = {
  hover: {
    y: "-100%",
    transition: {
      ...DEFAULT_TRANSITION,
      duration: 0.8,
      delay: 0,
    },
  },
  default: {
    y: 0,
    transition: {
      ...DEFAULT_TRANSITION,
      duration: 0.8,
      delay: 0,
    },
  },
};

const servicesRegex = new RegExp("^\\/services\\/[a-z,A-Z,-_|]*\\/");

export const Header: FC = () => {
  const ref = useRef<HTMLElement>(null);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const { theme } = useContext(ThemeContext);

  const { location } = useContext(LocationContext);
  const { data } = useContext(ProductInfoContext);
  const {
    refs: { scrollerRef, productHeroRef },
    setRefs,
  } = useContext(RefsContext);

  useEffect(() => {
    if (ref.current) {
      setRefs({ headerRef: ref });
    }
  }, []);

  useEffect(() => {
    if (data === null) setIsProductHeader(false);
  }, [data]);

  const handleAnchorLink = useCallback(
    (e: MouseEvent<HTMLAnchorElement>, link: string) => {
      setIsMenuOpened(false);
      if (
        !(link.startsWith("/#") || link === "/") ||
        location.pathname !== "/"
      ) {
        return e;
      }
      e.preventDefault();

      if (!scrollerRef?.current) return;

      const element = document.querySelector(
        `#section-${link.slice(2)}`
      ) as HTMLElement | null;

      const offsetTop =
        link === "/"
          ? 0
          : element
          ? element.offsetTop - (ref.current?.offsetHeight ?? 0)
          : null;

      if (offsetTop !== null) {
        scrollerRef.current.scrollTo({ top: offsetTop, behavior: "smooth" });
      }

      return;
    },
    [location.pathname, scrollerRef]
  );

  // Product header logic
  const [isProductHeader, setIsProductHeader] = useState(false);
  const heroEnds = useRef(0);
  const lastScroll = useRef(0);

  const handleResize = useDebouncedCallback(() => {
    if (!productHeroRef?.current) return;

    heroEnds.current =
      productHeroRef.current.offsetTop + productHeroRef.current.offsetHeight;
  }, 200);

  useEffect(() => {
    if (
      !data ||
      !(
        location.pathname.includes("/project/") ||
        servicesRegex.test(location.pathname)
      )
    )
      return;
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [data, handleResize, location.pathname]);

  const handleScroll = useThrottledCallback((e: any) => {
    if (
      !data ||
      !(
        location.pathname.includes("/project/") ||
        servicesRegex.test(location.pathname)
      )
    )
      return;

    const currentScroll = e.target.scrollTop;
    if (
      currentScroll > lastScroll.current &&
      currentScroll > heroEnds.current
    ) {
      setIsProductHeader(true);
    } else {
      setIsProductHeader(false);
    }
    lastScroll.current = currentScroll;
  }, 200);

  useEffect(() => {
    const container = scrollerRef?.current;
    if (
      !data ||
      !(
        location.pathname.includes("/project/") ||
        servicesRegex.test(location.pathname)
      ) ||
      !container
    )
      return;

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [data, handleScroll, location.pathname, scrollerRef]);

  const controlsName = useAnimation();

  const handleLogoEnter = () => {
    controlsName.start("hover");
  };

  const handleLogoLeave = () => {
    controlsName.start("default");
  };

  const cameFromIndex = (location.state as any)?.prevPath;

  return (
    <header
      ref={ref}
      id="header"
      className={cx(
        s.root,
        { [s.dark]: theme === THEME.dark },
        {
          [s.withProduct]:
            data !== null &&
            (location.pathname.includes("/project/") ||
              servicesRegex.test(location.pathname)),
        },
        {
          [s.productActive]:
            isProductHeader &&
            (location.pathname.includes("/project/") ||
              servicesRegex.test(location.pathname)),
        },
        { [s.menuOpened]: isMenuOpened }
      )}
    >
      <div className={s.background} />
      <Link
        id="header-logo"
        to="/"
        onClick={() => setIsMenuOpened(false)}
        aria-label={`Home | ${DEFAULT_SEO.SITE_NAME}`}
        className={s.logo}
      >
        <span className={s.logoIconWrapper}>
          <Logo className={s.logoIcon} />
          <LogoWhite
            className={cx(s.logoIcon, s.logoIconWhite, {
              [s.logoIconWhiteActive]: isMenuOpened,
            })}
          />
        </span>
        <span className={s.logoContent}>
          <strong>Stake</strong>me.io
        </span>
      </Link>
      <button
        id="header-menu-burger"
        type="button"
        aria-label={isMenuOpened ? "Close menu" : "Open menu"}
        onClick={() => setIsMenuOpened((prevState) => !prevState)}
        className={s.burger}
      >
        <span className={s.line} />
        <span className={s.line} />
      </button>
      <div className={s.content}>
        <div className={s.defaultContent}>
          <nav className={s.nav}>
            {NAVIGATION.map(({ link, label }) => (
              <Link
                id={`header-menu-item-${label.toLowerCase()}`}
                key={link}
                to={link}
                onClick={(e) => handleAnchorLink(e, link)}
                className={cx("header-menu-item", s.link)}
              >
                {label}
              </Link>
            ))}
          </nav>
          <div className={s.mediumWrapper}>
            <ModeToggle mobileRevert className={s.modeToggle} />
            <TelegramBotButton mobileDark />
            <a
              id="header-twitter"
              href="https://twitter.com/ProNodes_val"
              aria-label={`Twitter | ${DEFAULT_SEO.SITE_NAME}`}
              target="_blank"
              rel="noopener nofollow noreferrer"
              className={s.medium}
            >
              <TwitterIcon className={s.mediumIcon} />
            </a>
          </div>
        </div>
        {data && location.pathname !== "/" ? (
          <div className={s.productContent}>
            <Link
              id="header-product-back"
              to={
                cameFromIndex
                  ? data.service
                    ? `/services/${data.service.uid}`
                    : "/#portfolio"
                  : "/"
              }
              onMouseEnter={handleLogoEnter}
              onMouseLeave={handleLogoLeave}
              className={s.productInfo}
            >
              <ArrowLeft className={s.productArrow} />
              <GatsbyImage
                alt={data.name}
                image={data.icon}
                className={s.productIcon}
              />

              <span className={s.productName}>
                <motion.span
                  initial="default"
                  animate={controlsName}
                  variants={nameVariants}
                  className={s.productNameAnimated}
                >
                  <span className={s.productNameInner}>{data.name}</span>
                  <span className={s.productNameInner}>
                    {cameFromIndex
                      ? data.service
                        ? data.service.name
                        : "Back"
                      : "Home"}
                  </span>
                </motion.span>
              </span>
            </Link>
            {data.service ? (
              <TelegramBotButton className={s.serviceTelegram} />
            ) : (
              <Button
                id="header-product-delegate"
                href={data.link}
                className={s.productButton}
              >
                Start delegating
              </Button>
            )}
          </div>
        ) : null}
      </div>
    </header>
  );
};
