import React, { createContext, RefObject, useState } from "react";

type RefsContextDataType = {
  scrollerRef: RefObject<HTMLDivElement> | undefined;
  headerRef?: RefObject<HTMLDivElement> | undefined;
  productHeroRef?: RefObject<HTMLDivElement> | undefined;
  productCalculateRef?: RefObject<HTMLDivElement> | undefined;
};

type RefsContextType = {
  refs: RefsContextDataType;
  setRefs: (value: { [key: string]: RefObject<HTMLElement> }) => void;
};

const initialContextData = {
  scrollerRef: undefined,
  headerRef: undefined,
  productHeroRef: undefined,
  productCalculateRef: undefined,
};

const initialContextValue = {
  refs: initialContextData,
  setRefs: () => null,
};

export const RefsContext = createContext<RefsContextType>(initialContextValue);

type RefsProviderType = {
  value: { scrollerRef: RefObject<HTMLDivElement> | undefined };
};

export const RefsProvider: FC<RefsProviderType> = ({ value, children }) => {
  const [refs, setRefs] = useState<RefsContextDataType>(value);

  const handleSetData = (val: { [key: string]: RefObject<HTMLElement> }) => {
    setRefs((prevState) => ({ ...prevState, ...val }));
  };

  return (
    <RefsContext.Provider value={{ refs, setRefs: handleSetData }}>
      {children}
    </RefsContext.Provider>
  );
};
