export const NAVIGATION = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "About",
    link: "/#about",
  },
  {
    label: "Portfolio",
    link: "/#portfolio",
  },
  // {
  //   label: "Testnets",
  //   link: "/#testnets",
  // },
  {
    label: "Team",
    link: "/#team",
  },
  {
    label: "Community",
    link: "/#community",
  },
  // {
  //   label: "Education",
  //   link: "/education",
  // },
];
