import { getImage } from "gatsby-plugin-image";

import { ISeo, IService, IServiceProjectFull } from "types";

export const prepareServiceProjectData = (data: any) => {
  const project = data.prismicTendermintProjectService.data;
  const servicesData = data.allPrismicTendermintProjectService.nodes;

  const seo = {
    title: project.seo_title,
    description: project.seo_description,
  };

  const info = {
    name: project.project.document.data.name,
    iconUrl: project.project.document.data.icon.url,
    icon: getImage(project.project.document.data.icon.localFile),
    content: project.content.map((item: any) => ({
      title: item.content_title.richText,
      content: item.content_content.richText,
    })),
  };

  const services = servicesData
    ?.map((service: any) =>
      service &&
      service.data &&
      service.data.service &&
      service.data.service.document &&
      service.data.service.document.uid &&
      service.data.service.document.data &&
      service.data.service.document.data.name
        ? {
            uid: service.data.service.document.uid,
            name: service.data.service.document.data.name,
            color: service.data.service.document.data.color,
            darkColor: service.data.service.document.data.dark_color,
          }
        : null
    )
    .filter((el: IService | null) => el) as IService[] | undefined;

  const preparedNextData = data.nextProject.edges.length
    ? data.nextProject.edges
        .map((prjct: any) => {
          const project = prjct.next
            ? prjct
            : data.nextProject.edges[0].next
            ? data.nextProject.edges[0]
            : null;
          if (!project) return null;
          return {
            uid: prjct.next
              ? project.node.data.project.document.uid
              : prjct.node.data.project.document.uid,
            next: {
              uid: project.next.data.project.document.uid,
              color: project.next.data.project.document.data.color,
              darkColor: project.next.data.project.document.data.dark_color,
              name: project.next.data.project.document.data.name,
              icon: getImage(
                project.next.data.project.document.data.icon.localFile
              ),
            },
          };
        })
        .filter((el: any) => el)
    : null;
  const nextProject =
    preparedNextData?.find((el: any) => el.uid === project.project.uid)?.next ??
    null;

  return {
    seo,
    ...info,
    services,
    nextProject,
  } as IServiceProjectFull & ISeo;
};
