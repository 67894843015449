import { graphql, useStaticQuery } from "gatsby";

import { IService } from "types";

export const useHomeServices = () => {
  const data = useStaticQuery(graphql`
    query Services {
      prismicHomePage {
        data {
          tendermint_services {
            tendermint_service {
              document {
                ... on PrismicTendermintService {
                  uid
                  data {
                    name
                    color
                    dark_color
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.prismicHomePage.data.tendermint_services
    ?.map((el: any) =>
      el &&
      el.tendermint_service &&
      el.tendermint_service.document &&
      el.tendermint_service.document.uid &&
      el.tendermint_service.document.data &&
      el.tendermint_service.document.data.name
        ? {
            uid: el.tendermint_service.document.uid,
            name: el.tendermint_service.document.data.name,
            color: el.tendermint_service.document.data.color,
            darkColor: el.tendermint_service.document.data.dark_color,
          }
        : null
    )
    .filter((el: IService | null) => el) as IService[] | undefined;
};
