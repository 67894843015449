import useSWR from "swr";

import { COINGECKO_API } from "utils/constants";
import { fetcher } from "utils/helpers";

export const useTokenPrice = (id: string) =>
  useSWR(
    `${COINGECKO_API.URL}?${COINGECKO_API.IDS_KEY}=${id}&${COINGECKO_API.CURRENCY_KEY}=${COINGECKO_API.DEFAULT_CURRENCY}`,
    fetcher
  );
