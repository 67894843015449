module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TT2FF4W","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"236657ebe56c0ec90950e5d150745670"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Stake your crypto with a reliable validator","titleTemplate":"%s | stakeme.io","defaultTitle":"Stake your crypto with a reliable validator","description":"Trusted cryptocurrency validator. We offer transparent staking conditions for cryptocurrency with favorable terms.","openGraph":{"type":"website","locale":"en","url":"https://stakeme.io/","site_name":"stakeme.io","title":"Stake your crypto with a reliable validator | stakeme.io","titleTemplate":"%s | stakeme.io","description":"Trusted cryptocurrency validator. We offer transparent staking conditions for cryptocurrency with favorable terms.","images":[{"url":"https://og-images.stakeme.io/api/og-index?description=Trusted%20cryptocurrency%20validator.%20We%20offer%20transparent%20staking%20conditions%20for%20cryptocurrency%20with%20favorable%20terms.&mainnets=8&testnets=12","width":1200,"height":632,"alt":"Stake your crypto with a reliable validator | stakeme.io"}]},"twitter":{"handle":"@ProNodes_val","site":"@ProNodes_val","cardType":"summary_large_image"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
