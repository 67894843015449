import * as React from "react";
import { useEffect, useRef } from "react";
import cx from "classnames";
import { motion, useAnimation, useInView } from "framer-motion";

import { DEFAULT_TRANSITION } from "utils/constants";

import * as s from "./AnimatedLine.module.sass";

type AnimatedLineProps = {
  align?: keyof typeof alignClass;
  verticalAlign?: keyof typeof verticalAlignClass;
  direction?: keyof typeof directionClass;
  duration?: number;
  delay?: number;
  fromStart?: boolean;
  className?: string;
};

const directionClass = {
  horizontal: s.horizontal,
  vertical: s.vertical,
};

const verticalAlignClass = {
  top: s.top,
  bottom: s.bottom,
};

const alignClass = {
  left: s.left,
  right: s.right,
};

export const AnimatedLine: React.FC<AnimatedLineProps> = ({
  direction = "vertical",
  align = "left",
  verticalAlign = "top",
  duration = DEFAULT_TRANSITION.duration,
  delay = DEFAULT_TRANSITION.delay,
  fromStart = false,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const classNames = cx(
    s.root,
    directionClass[direction],
    alignClass[align],
    verticalAlignClass[verticalAlign],
    className
  );

  const onScreen = useInView(ref);
  const controls = useAnimation();

  useEffect(() => {
    if (onScreen || fromStart) {
      controls.start(
        direction === "horizontal" ? { scaleX: 1 } : { scaleY: 1 }
      );
    }
  }, [controls, direction, fromStart, onScreen]);

  return (
    <motion.div
      ref={ref}
      className={classNames}
      style={{ originX: 0, originY: 0 }}
      initial={direction === "horizontal" ? { scaleX: 0 } : { scaleY: 0 }}
      animate={controls}
      transition={{
        ease: [0.6, 0.01, 0, 0.95],
        duration: duration,
        delay: delay,
      }}
    />
  );
};
