import { graphql, useStaticQuery } from "gatsby";

export const useHomeHero = () => {
  const data = useStaticQuery(graphql`
    query HeroInfo {
      prismicHomePage {
        last_publication_date
        data {
          hero_description
          testnets_amount
        }
      }
      allPrismicProject(sort: { last_publication_date: DESC }, limit: 1) {
        pageInfo {
          totalCount
        }
        nodes {
          last_publication_date
        }
      }
    }
  `);

  return {
    description: data.prismicHomePage.data.hero_description,
    mainnets: {
      amount: data.allPrismicProject.pageInfo.totalCount,
      upd: data.allPrismicProject.nodes?.[0]?.last_publication_date ?? null,
    },
    testnets: {
      amount: data.prismicHomePage.data.testnets_amount,
      upd: data.prismicHomePage.last_publication_date ?? null,
    },
  };
};
