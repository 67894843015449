import React, { createContext, useEffect, useState } from "react";

export enum THEME {
  dark = "DARK",
  light = "LIGHT",
}

export const THEME_LS_KEY = "THEME";

type ThemeContextType = {
  theme: THEME | undefined;
  setTheme: (value: THEME) => void;
};

const initialContextValue = {
  theme: undefined,
  setTheme: () => null,
};

export const ThemeContext =
  createContext<ThemeContextType>(initialContextValue);

export const ThemeProvider: FC = ({ children }) => {
  const [theme, rawSetTheme] = useState<THEME | undefined>(undefined);

  useEffect(() => {
    const root = window.document.documentElement;
    const initialColorValue = root.style.getPropertyValue(
      "--initial-color-mode"
    );
    rawSetTheme(initialColorValue === "LIGHT" ? THEME.light : THEME.dark);
  }, []);

  const setTheme = (value: THEME) => {
    rawSetTheme(value);

    window.localStorage.setItem(THEME_LS_KEY, value);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
