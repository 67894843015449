import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { WindowLocation } from "@reach/router";
import { useDebouncedCallback } from "use-debounce";
import cx from "classnames";

import { LocationProvider } from "components/common/LocationProvider";
import { RefsProvider } from "components/common/RefsProvider";
import { ProductInfoProvider } from "components/common/ProductInfoProvider";
import { Header } from "components/common/Header";
import { ModeToggle } from "components/common/ModeToggle";
import { Footer } from "components/common/Footer";

import "styles/global.sass";

import * as s from "./Layout.module.sass";

type LayoutProps = {
  location: WindowLocation<WindowLocation["state"]>;
};

export const Layout: FC<LayoutProps> = ({ location, children }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!location.hash) {
      containerRef.current?.scrollTo(0, 0);
      return;
    }
    const timer = setTimeout(() => {
      const element = document.querySelector(
        `#section-${location.hash.slice(1)}`
      ) as HTMLElement | null;

      const offsetTop = element
        ? element.offsetTop -
          ((document.querySelector("#header") as HTMLElement | null)
            ?.offsetHeight ?? 0)
        : null;

      if (offsetTop !== null) {
        containerRef.current?.scrollTo({ top: offsetTop });
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [location.pathname]);

  const handleResize = useDebouncedCallback(() => {
    const vh = (window?.innerHeight ?? 0) * 0.01;

    if (vh !== 0) {
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
  }, 200);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  return (
    <LocationProvider location={location}>
      <RefsProvider value={{ scrollerRef: containerRef }}>
        <ProductInfoProvider>
          <Helmet
            htmlAttributes={{
              lang: "en",
            }}
          />
          <div className={cx(s.root)}>
            <Header />
            <main className={s.main}>
              <div className={s.sidebar}>
                <ModeToggle />
              </div>
              <div ref={containerRef} className={s.content}>
                {children}
                {location.pathname === "/" ? <Footer /> : null}
              </div>
            </main>
          </div>
        </ProductInfoProvider>
      </RefsProvider>
    </LocationProvider>
  );
};
