import React, { useContext } from "react";

import { THEME, ThemeContext } from "components/common/ThemeProvider";

export const Logo: FC = ({ className }) => {
  const { theme } = useContext(ThemeContext);
  const isDarkMode = theme === THEME.dark;

  return (
    <svg
      width="36"
      height="41"
      viewBox="0 0 36 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M32.3037 20.1673L23.6539 15.1146L20.1765 13.0821L7.01112 5.38574L3.31793 7.54489C1.26338 8.7416 0 10.9609 0 13.3575C0 16.0295 1.40723 18.4957 3.69319 19.8318L12.3429 24.8877L15.8235 26.9202L28.9889 34.6165L32.6821 32.4574C34.7335 31.2575 36 29.0414 36 26.6416C35.9937 23.9728 34.5865 21.5034 32.3037 20.1673Z"
        fill={isDarkMode ? "#5B5BFF" : "#3535DB"}
      />
      <path
        d="M32.6955 7.55463C32.9551 7.70976 33.1364 7.93454 33.2396 8.18465C33.5336 8.90647 33.1083 9.72328 32.3609 9.9164L17.4255 6.98794L14.6982 0.940633L17.4255 6.9911L32.3609 9.9164L20.1712 13.0791L7.0058 5.38599L14.6799 0.899908C16.7313 -0.299969 19.2612 -0.299969 21.3126 0.899908L32.6767 7.54514L32.6955 7.55463Z"
        fill="url(#logo-paint0_linear_390_67)"
      />
      <path
        d="M15.8208 26.9258L3.63421 30.0854L18.5695 33.0138L21.3152 39.105L28.9862 34.6189L15.8208 26.9258Z"
        fill="url(#logo-paint1_linear_390_67)"
      />
      <path
        d="M3.29645 32.4503C3.03689 32.2952 2.85552 32.0704 2.75232 31.8203C2.46149 31.0984 2.88679 30.2816 3.63105 30.0854L18.5664 33.0138L21.3121 39.1018C19.2607 40.3017 16.7308 40.3017 14.6794 39.1018L3.31521 32.4598L3.29645 32.4503Z"
        fill="url(#logo-paint2_linear_390_67)"
      />
      <defs>
        <linearGradient
          id="logo-paint0_linear_390_67"
          x1="27.6329"
          y1="8.42423"
          x2="6.99884"
          y2="10.7177"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isDarkMode ? "#4848FF" : "#4747EF"} />
          <stop offset="1" stopColor={isDarkMode ? "#2C2CBD" : "#000099"} />
        </linearGradient>
        <linearGradient
          id="logo-paint1_linear_390_67"
          x1="10.5552"
          y1="33.433"
          x2="24.9218"
          y2="32.4374"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isDarkMode ? "#4848FF" : "#4747EF"} />
          <stop offset="1" stopColor={isDarkMode ? "#2C2CBD" : "#000099"} />
        </linearGradient>
        <linearGradient
          id="logo-paint2_linear_390_67"
          x1="10.5552"
          y1="33.433"
          x2="24.9218"
          y2="32.4374"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={isDarkMode ? "#4848FF" : "#4747EF"} />
          <stop offset="1" stopColor={isDarkMode ? "#2C2CBD" : "#000099"} />
        </linearGradient>
      </defs>
    </svg>
  );
};
