exports.components = {
  "component---src-components-templates-blog-article-page-blog-article-page-tsx": () => import("./../../../src/components/templates/BlogArticlePage/BlogArticlePage.tsx" /* webpackChunkName: "component---src-components-templates-blog-article-page-blog-article-page-tsx" */),
  "component---src-components-templates-blog-category-page-blog-category-page-tsx": () => import("./../../../src/components/templates/BlogCategoryPage/BlogCategoryPage.tsx" /* webpackChunkName: "component---src-components-templates-blog-category-page-blog-category-page-tsx" */),
  "component---src-components-templates-product-page-product-page-tsx": () => import("./../../../src/components/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---src-components-templates-product-page-product-page-tsx" */),
  "component---src-components-templates-service-page-service-page-tsx": () => import("./../../../src/components/templates/ServicePage/ServicePage.tsx" /* webpackChunkName: "component---src-components-templates-service-page-service-page-tsx" */),
  "component---src-components-templates-service-project-page-service-project-page-tsx": () => import("./../../../src/components/templates/ServiceProjectPage/ServiceProjectPage.tsx" /* webpackChunkName: "component---src-components-templates-service-project-page-service-project-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

