import React from "react";
import type { GatsbyBrowser } from "gatsby";

import { ColorProvider } from "./src/components/common/ColorContext";
import Layout from "./src/components/layouts/Layout";
import { ThemeProvider } from "./src/components/common/ThemeProvider";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}: any) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <ThemeProvider>
      <ColorProvider pathname={props.location.pathname}>
        <Layout {...props}>{element}</Layout>
      </ColorProvider>
    </ThemeProvider>
  );
};
