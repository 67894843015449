import { graphql, useStaticQuery } from "gatsby";

export const useTelegramBotLink = () => {
  const data = useStaticQuery(graphql`
    query TelegramLink {
      prismicHomePage {
        data {
          telegram_bot_link {
            ... on PrismicLinkType {
              url
            }
          }
        }
      }
    }
  `);

  return data.prismicHomePage?.data?.telegram_bot_link?.url;
};
