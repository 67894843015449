import React, { createContext, useState } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";

type ProductInfoContextDataType = {
  icon: IGatsbyImageData;
  name: string;
  link?: string;
  service?: {
    uid: string;
    name: string;
  };
};

type ProductInfoContextType = {
  data: ProductInfoContextDataType | null;
  setData: (value: ProductInfoContextDataType | null) => void;
};

const initialContextValue = {
  data: null,
  setData: () => null,
};

export const ProductInfoContext =
  createContext<ProductInfoContextType>(initialContextValue);

export const ProductInfoProvider: FC = ({ children }) => {
  const [data, setData] = useState<ProductInfoContextDataType | null>(null);

  return (
    <ProductInfoContext.Provider value={{ data, setData }}>
      {children}
    </ProductInfoContext.Provider>
  );
};
