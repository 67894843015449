import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { IProjectNext } from "types";

export const useNextProject = (uid: string) => {
  const data = useStaticQuery(graphql`
    query Projects {
      allPrismicProject {
        edges {
          node {
            uid
          }
          next {
            uid
            data {
              primary_color
              dark_color
              name
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 96, placeholder: BLURRED, height: 96)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const preparedArr = data.allPrismicProject.edges.length
    ? data.allPrismicProject.edges.map((prjct: any) => {
        const project = prjct.next ? prjct : data.allPrismicProject.edges[0];
        return {
          uid: prjct.next ? project.node.uid : prjct.node.uid,
          next: {
            uid: project.next.uid,
            color: project.next.data.primary_color,
            darkColor: project.next.data.dark_color,
            name: project.next.data.name,
            icon: getImage(project.next.data.icon.localFile),
          },
        };
      })
    : null;

  return (preparedArr?.find((el: any) => el.uid === uid)?.next ??
    null) as IProjectNext;
};
