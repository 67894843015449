// @ts-nocheck

const rgb2hsv = (r, g, b) => {
  const v = Math.max(r, g, b),
    n = v - Math.min(r, g, b);
  const h =
    n && (v == r ? (g - b) / n : v == g ? 2 + (b - r) / n : 4 + (r - g) / n);
  return [60 * (h < 0 ? h + 6 : h), v && n / v, v];
};

const clrLkp = [
  ["red", 0],
  ["vermilion", 15],
  ["brown", 20],
  ["orange", 30],
  ["safran", 45],
  ["yellow", 60],
  ["light green yellow", 75],
  ["green yellow", 90],
  ["limett", 105],
  ["dark green", 120],
  ["green", 120],
  ["light blue-green", 135],
  ["blue green", 150],
  ["green cyan", 165],
  ["cyan", 180],
  ["blaucyan", 195],
  ["green blue", 210],
  ["light green blue", 225],
  ["blue", 240],
  ["indigo", 255],
  ["violet", 270],
  ["blue magenta", 285],
  ["magenta", 300],
  ["red magenta", 315],
  ["blue red", 330],
  ["light blue red", 345],
].reverse();

const hex2rgb = (hex) => {
  const parts = hex.slice(1).match(/../g);
  if (!parts || parts.length < 3) throw new Error("Invalid hex value");
  return parts.map((p) => parseInt(p, 16));
};
const hsv2name = (h) => clrLkp.find(([, val]) => h >= val)[0];
const hex2name = (hex) =>
  [hex2rgb, rgb2hsv, hsv2name].reduce((a, v) => v(...[a].flat()), hex);

export const isColorRed = (color) => hex2name(color).includes("red");
