import { graphql, useStaticQuery } from "gatsby";

import { IBlogCategory } from "types";

export const useBlogCategories = () => {
  const data = useStaticQuery(graphql`
    query BlogCategories {
      allPrismicBlogCategory(sort: { last_publication_date: DESC }) {
        nodes {
          uid
          data {
            name
            color
            dark_color
          }
        }
      }
    }
  `);

  return data.allPrismicBlogCategory.nodes
    ?.map((cat: any) =>
      cat && cat.uid && cat.data.name
        ? {
            uid: cat.uid,
            name: cat.data.name,
            color: cat.data.color,
            darkColor: cat.data.dark_color,
          }
        : null
    )
    .filter((el: IBlogCategory | null) => el) as IBlogCategory[];
};
