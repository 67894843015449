import React, { useContext } from "react";
import cx from "classnames";

import { THEME, ThemeContext } from "components/common/ThemeProvider";
import LightModeIcon from "svg/light-mode.svg";
import DarkModeIcon from "svg/dark-mode.svg";

import * as s from "./ModeToggle.module.sass";

type ModeToggleProps = {
  mobileRevert?: boolean;
};

export const ModeToggle: FC<ModeToggleProps> = ({
  mobileRevert,
  className,
}) => {
  const { theme, setTheme } = useContext(ThemeContext);

  if (!theme) {
    return null;
  }

  const toggle = () => {
    setTheme(theme === THEME.light ? THEME.dark : THEME.light);
  };

  return (
    <button
      type="button"
      onClick={toggle}
      aria-label={`Switch to ${theme === THEME.light ? "dark" : "light"} mode`}
      className={cx(
        s.root,
        { [s.dark]: theme === THEME.dark },
        { [s.mobileRevert]: mobileRevert },
        className
      )}
    >
      <span className={s.toggle}>
        <LightModeIcon className={s.lightIcon} />
        <DarkModeIcon className={s.darkIcon} />
      </span>
    </button>
  );
};
