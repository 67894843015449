import { getImage } from "gatsby-plugin-image";

import { ISeo, IService, IServiceFull, IServiceProject } from "types";

export const prepareServiceData = (data: any) => {
  const service = data.prismicTendermintService.data;
  const projects = data.allPrismicTendermintProjectService.nodes;
  const otherServices = data.allPrismicTendermintService.nodes;

  return {
    service: {
      seo: {
        title: service.seo_title,
        description: service.seo_description,
      },
      name: service.name,
      description: service.description,
      color: service.color,
      projects: projects
        ?.map((project: any) =>
          project &&
          project.data &&
          project.data.project &&
          project.data.project.document &&
          project.data.project.document.uid &&
          project.data.project.document.data &&
          project.data.project.document.data.name
            ? {
                uid: project.data.project.document.uid,
                name: project.data.project.document.data.name,
                icon: getImage(
                  project.data.project.document.data.icon.localFile
                ),
                color: project.data.project.document.data.color,
                darkColor: project.data.project.document.data.dark_color,
              }
            : null
        )
        .filter((el: IServiceProject | null) => el) as
        | IServiceProject[]
        | undefined,
    } as IServiceFull & ISeo,
    otherServices: otherServices
      ?.map((service: any) =>
        service.uid && service.data && service.data.name && service.data.color
          ? {
              uid: service.uid,
              name: service.data.name,
              color: service.data.color,
              darkColor: service.data.dark_color,
            }
          : null
      )
      .filter((el: IService | null) => el) as IService[] | undefined,
  };
};
