export const prepareTitle = (
  text: string,
  spans: [{ start: number; end: number }]
) => {
  if (spans && spans.length === 1) {
    return spans.map(({ start, end }) =>
      [
        { text: text.slice(0, start).trim() },
        { text: text.slice(start, end).trim(), underlined: true },
        { text: text.slice(end).trim() },
      ].filter(({ text }) => text !== "")
    )[0];
  }

  return [{ text }];
};
