import { getImage } from "gatsby-plugin-image";

import { IBlogArticle, IBlogArticleFull, IBlogCategory } from "types";

export const prepareBlogArticleData = (data: any) => {
  const article = data.prismicBlogArticle;
  const articleData = data.prismicBlogArticle.data;
  const similarArticles = data.allPrismicBlogArticle.nodes;

  return {
    hero: {
      imageUrl: articleData.image.url,
      firstPublishDate: article.first_publication_date,
      date: article.last_publication_date,
      image: getImage(articleData.image.localFile),
      title: articleData.title,
      description: articleData.description,
      categories: articleData.categories
        ?.map((cat: any) =>
          cat &&
          cat.category &&
          cat.category.document &&
          cat.category.document.uid &&
          cat.category.document.data.name
            ? {
                uid: cat.category.document.uid,
                name: cat.category.document.data.name,
                color: cat.category.document.data.color,
                darkColor: cat.category.document.data.dark_color,
              }
            : null
        )
        .filter((el: IBlogCategory | null) => el) as
        | IBlogCategory[]
        | undefined,
    },
    content: articleData.content.richText,
    similarArticles: similarArticles
      ?.map((article: any) =>
        article && article.uid && article.data.title
          ? {
              uid: article.uid,
              date: article.last_publication_date,
              image: getImage(article.data.image.localFile),
              title: article.data.title,
              color:
                article.data.categories?.[0]?.category.document?.data?.color,
              darkColor:
                article.data.categories?.[0]?.category.document?.data
                  ?.dark_color,
              categories: article.data.categories
                ?.map((cat: any) =>
                  cat &&
                  cat.category &&
                  cat.category.document &&
                  cat.category.document.uid &&
                  cat.category.document.data.name
                    ? {
                        uid: cat.category.document.uid,
                        name: cat.category.document.data.name,
                        color: cat.category.document.data.color,
                        darkColor: cat.category.document.data.dark_color,
                      }
                    : null
                )
                .filter((el: IBlogCategory | null) => el) as
                | IBlogCategory[]
                | undefined,
            }
          : null
      )
      .filter((el: IBlogArticle | null) => el) as IBlogArticle[] | undefined,
  } as IBlogArticleFull;
};
