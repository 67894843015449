import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const getPrettyDate = (date: string, mini = false) => {
  const newDate = dayjs(date);
  const dayDiff = dayjs().diff(newDate, "day");
  const yearDiff = dayjs().diff(newDate, "year");

  let finalDate;
  if (dayDiff < (mini ? 8 : 90)) {
    finalDate = newDate.fromNow();
  } else {
    finalDate =
      yearDiff === 0 ? newDate.format("D MMMM") : newDate.format("D MMMM YYYY");
  }
  return finalDate;
};
