// extracted by mini-css-extract-plugin
export var background = "Header-module--background--2bac6";
export var burger = "Header-module--burger--c6767";
export var content = "Header-module--content--ca7cd";
export var dark = "Header-module--dark--e9bd5";
export var defaultContent = "Header-module--defaultContent--a8743";
export var line = "Header-module--line--c90fd";
export var link = "Header-module--link--ec51e";
export var logo = "Header-module--logo--87958";
export var logoContent = "Header-module--logoContent--305e5";
export var logoIcon = "Header-module--logoIcon--dad1f";
export var logoIconWhite = "Header-module--logoIconWhite--e9907";
export var logoIconWhiteActive = "Header-module--logoIconWhiteActive--ad434";
export var logoIconWrapper = "Header-module--logoIconWrapper--04042";
export var medium = "Header-module--medium--36d49";
export var mediumIcon = "Header-module--mediumIcon--8e76f";
export var mediumWrapper = "Header-module--mediumWrapper--daffd";
export var menuOpened = "Header-module--menuOpened--4c9db";
export var modeToggle = "Header-module--modeToggle--e4d0e";
export var nav = "Header-module--nav--86664";
export var productActive = "Header-module--productActive--16604";
export var productArrow = "Header-module--productArrow--3d97f";
export var productButton = "Header-module--productButton--c560c";
export var productContent = "Header-module--productContent--8c86e";
export var productIcon = "Header-module--productIcon--0c907";
export var productInfo = "Header-module--productInfo--1b573";
export var productName = "Header-module--productName--3034a";
export var productNameAnimated = "Header-module--productNameAnimated--3670b";
export var root = "Header-module--root--6c0e7";
export var serviceTelegram = "Header-module--serviceTelegram--2b3c8";
export var withProduct = "Header-module--withProduct--7bea5";