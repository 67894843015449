import { graphql, useStaticQuery } from "gatsby";

import { IContact } from "types";

export const useHomeContacts = () => {
  const data = useStaticQuery(graphql`
    query Contacts {
      prismicHomePage {
        data {
          contacts {
            contacts_social_media
            contacts_link {
              url
            }
          }
        }
      }
    }
  `);

  return data.prismicHomePage.data.contacts
    ?.map((el: any) =>
      el && el.contacts_link.url
        ? {
            type: el.contacts_social_media,
            link: el.contacts_link.url,
          }
        : null
    )
    .filter((el: IContact | null) => el) as IContact[] | undefined;
};
