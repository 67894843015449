import { getImage } from "gatsby-plugin-image";

import { IProjectFull } from "types";

import { getYoutubeId } from "./getYoutubeId";

export const prepareProductData = (data: any) => {
  const project = data.prismicProject.data;

  return {
    apiId: project.api_id,
    iconUrl: project.icon.url,
    tutorialThumbnailUrl: project.tutorial_video_thumbnail?.url,
    info: {
      icon: getImage(project.icon.localFile),
      name: project.name,
      symbol: project.symbol,
      apr: project.apr,
      commission: project.comission,
      unbondingPeriod: project.unbonding_period,
      minimumStakeAmount: project.minimum_stake_amount,
      maximumStakeAmount: project.maximum_stake_amount,
      totalStakedAmount: project.total_staked_amount,
      validatorAddresses: project.validator_addresses
        ?.map((address: any) => (address ? address.validator_address : null))
        .filter((el: any) => el),
      link: project.validator_link.url,
    },
    tutorial:
      project.tutorial_video_link?.url &&
      getImage(project.tutorial_video_thumbnail.localFile)
        ? {
            youtubeId: getYoutubeId(project.tutorial_video_link.url),
            thumbnail: getImage(project.tutorial_video_thumbnail.localFile),
          }
        : undefined,
    about: {
      content: project.about.richText,
      tokenAddress: project.token_address,
      socialLinks: project.social_links
        ?.map((social: any) =>
          social && social.social_links_link.url
            ? {
                type: social.social_links_social,
                link: social.social_links_link.url,
              }
            : null
        )
        .filter((el: any) => el),
      name: project.name,
      link: project.validator_link.url,
    },
    howTo:
      project.how_to && project.how_to.length
        ? project.how_to
            .map((el: any) =>
              el.how_to_title && el.how_to_content
                ? {
                    title: el.how_to_title,
                    content: {
                      richText: el.how_to_content.richText,
                      text: el.how_to_content.text,
                    },
                  }
                : null
            )
            .filter((el: any) => el)
        : undefined,
    faq:
      project.faq && project.faq.length
        ? project.faq
            .map((el: any) =>
              el.faq_question && el.faq_answer
                ? {
                    question: el.faq_question,
                    answer: {
                      richText: el.faq_answer.richText,
                      text: el.faq_answer.text,
                    },
                  }
                : null
            )
            .filter((el: any) => el)
        : undefined,
  } as IProjectFull;
};
